import {useState} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaEye } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import Layout from "../../components/layouts/layout";
import {useLang} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import { Modal } from 'rsuite';
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import {exportToExcel} from "../../libs/utility/helper";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {getPaginatedBRACForm, removeBracFromEntry} from "./bracForm";
import {Permission} from "../../configs/constants";
import {MdDeleteOutline} from "react-icons/md";
import ComponentGuard from "../../helpers/ComponentGuard";
import {DeleteButton} from "../../components/Buttons";

function BracFormListPage() {
  const {t} = useLang();
  const [fetchError, setFetchError] = useState(null);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [ selectedData, setSelectedData ] = useState(null);
  const [ selectedRows, setSelectedRows ] = useState([]);
  const {notifyError, notifySuccess} = useNotification();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {register, handleSubmit, control} = useForm();
  const {
    list,
    changePagination,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem
  } = usePaginatedList(getPaginatedBRACForm, removeBracFromEntry)

  const handleOpen = (id) => {
    setSelectedData(list?.data.find(item => item.id === id));
    console.log(selectedData);
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const fetchAllData = async () => {
    let allData = [];
    try {
      setIsExcelLoading(true)
      allData = await getPaginatedBRACForm({page: 1, limit: 1000000,})
    } catch (err) {
      setFetchError({
        title: "Something went wrong",
        message: err.message
      })
    }finally {
      setIsExcelLoading(false)
    }
    return allData;
  }

  const processExcelData = (excelData) => {
    const columnsToKeep = [
      "id", "name", "father_name", "mother_name", "village", "union_name", "upazilla",
      "district", "division", "area_sale_center", "highest_education_level",
      "education_stopping_year_or_running", "date_of_birth", "present_age",
      "physical_height", "present_occupation", "experience_on_ai_pc",
      "any_aisp_working_in_that_union", "nid_no", "phone_number", "email"
    ];
    const filteredData = excelData.map(obj => {
      const newObj = {};
      columnsToKeep.forEach(column => {
        if (obj.hasOwnProperty(column)) {
          let newColumnName = column.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          newObj[newColumnName] = obj[column];
        }
      });
      return newObj;
    });
    let name = `Registration Form ${moment().format('YYYY-MM-DD HH:mm:ss')}`
    exportToExcel(filteredData, name);
  }

  const handleExcelButtonClick = async () => {
    let fetchData = await fetchAllData();
    let excelData = fetchData?.data ?? null;
    processExcelData(excelData);
  }

  const handleDelete = async (id) => {
    try {
      await removeItem(id);
      notifySuccess({
        title: "Form Data removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove data!",
        message: err.message
      });
    }
  };

  const handleSelectionChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', selectedRows);
    setSelectedRows(selectedRows);
  };

  const handleSelectedExcelButtonClick = () => {
    processExcelData(selectedRows);
  }

  const columns = [
    {
      name: "#",
      cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("contactForm_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("contactForm_manage_col_email"),
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: t("contactForm_manage_col_phone_number"),
      selector: (row) => row.phone_number,
      sortable: true
    },
    {
      name: t("contactForm_manage_col_created_at"),
      selector: (row) => moment(row.created_at).format("YYYY-MM-DD"),
      sortable: true
    },
    {
      name: t("contactForm_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
              <Button onClick={() => handleOpen(m.id)}>
                <FaEye />
              </Button>
              <ComponentGuard needsPermission={[Permission.ComponentDelete]}>
                <DeleteButton handleDelete={() => handleDelete(m.id)}/>
              </ComponentGuard>
          </div>
        );
      }
    }
  ];
  console.log('State Selected Rows: ', selectedRows, selectedRows.length);
  return (
    <>
      <Layout>
        { isExcelLoading || isLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("forms")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="albumList" className="mb-5 pb-5">
          <h2>{t("contactForm_manage_BRACForms")}</h2>
          <div className="mt-3 mb-3">
            <Form
              onSubmit={handleSubmit((data) => {
                if (data.fromTo_date && data.fromTo_date.length === 2) {
                  data.fromDate = moment(data.fromTo_date[0]).format("yyyy-MM-DD");
                  data.toDate = moment(data.fromTo_date[1]).format("yyyy-MM-DD");
                }
                delete data.fromTo_date;
                filterDispatch({
                  type: "reInitialize",
                  payload: data
                });
              })}
            >
              <div className="row">
                <Form.Group
                  className="col-md-5 col-lg-5"
                  controlId="search"
                  onSubmit={handleSubmit((data) => {
                  })}
                >
                  <Form.Label className="fw-bold">{t("clientForm_manage_searchBy")}</Form.Label>
                  <Form.Control
                    {...register("term")}
                    placeholder={t("clientForm_manage_searchBy_p")}
                    custom
                  ></Form.Control>
                </Form.Group>
                <div className="col-md-3 col-lg-2 ml-auto">
                  <div className="h-100 d-flex justify-content-end align-items-center">
                    <Button className="px-5" type="submit">
                      {t("posts_manage_go")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <DataTable
            columns={columns}
            data={list?.data}
            progressPending={isLoading}
            selectableRows
            onSelectedRowsChange={handleSelectionChange}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list?.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
          />
          <div className="d-flex gap-4">
            <Button onClick={handleExcelButtonClick}>Export to Excel</Button>
            { Number(selectedRows.length) > 0 ?
              <Button onClick={handleSelectedExcelButtonClick}>Export Selected</Button> : ''
            }
          </div>
        </section>
        <Modal size="lg" open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>{t("details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container" id="myPDF" style={{ letterSpacing: '0.01px', fontFamily: 'Arial' }}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="card mb-3">
                    <div className="card-header" style={{ backgroundColor:'#EEEEEE'}}>
                      <h5 className="mb-0">{t("contactForm_manage_col_information")}</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_name_nid_base")}</label>
                            <p className="form-control-static">{selectedData?.name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_email")}</label>
                            <p className="form-control-static">{selectedData?.email}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_phone_number")}</label>
                            <p className="form-control-static">{selectedData?.phone_number}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_father_husband_name")}</label>
                            <p className="form-control-static">{selectedData?.father_name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_mother_name")}</label>
                            <p className="form-control-static">{selectedData?.mother_name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_village")}</label>
                            <p className="form-control-static">{selectedData?.village}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_union")}</label>
                            <p className="form-control-static">{selectedData?.union_name.name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_upazila")}</label>
                            <p className="form-control-static">{selectedData?.upazilla.name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_district")}</label>
                            <p className="form-control-static">{selectedData?.district.name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_division")}</label>
                            <p className="form-control-static">{selectedData?.division.name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_area_sale_center")}</label>
                            <p className="form-control-static">{selectedData?.area_sale_center}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_highest_education_level")}</label>
                            <p className="form-control-static">{selectedData?.highest_education_level}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_education_stopped_year_running")}</label>
                            <p className="form-control-static">{selectedData?.education_stopping_year_or_running}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_date_of_birth")}</label>
                            <p className="form-control-static">{selectedData?.date_of_birth}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_present_age")}</label>
                            <p className="form-control-static">{selectedData?.present_age}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_physical_height")}</label>
                            <p className="form-control-static">{selectedData?.physical_height}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_present_occupation")}</label>
                            <p className="form-control-static">{selectedData?.present_occupation}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_experience_on_ai_pc")}</label>
                            <p className="form-control-static">{selectedData?.experience_on_ai_pc}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_any_aisp_working_in_that_union_from_brac")}</label>
                            <p className="form-control-static">{selectedData?.any_aisp_working_in_that_union}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("bracFrom_nid_no")}</label>
                            <p className="form-control-static">{selectedData?.nid_no}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

        </Modal>
      </Layout>
    </>
  );
}

export default BracFormListPage;